<template>
  <div v-if="isDesktop" :class="{ stick: stickMenu }" class="mainmenu">
    <nuxt-link to="/" tag="div" class="logo" />
    <div v-if="!isKids" class="items">
      <div @mouseenter="triggerDrop('c1')" @mouseleave="triggerDrop()" class="item">
        <nuxt-link to="/film?type=films">
          Фильмы
        </nuxt-link>
        <transition name="fadeIn">
          <div v-show="dropList.c1" class="drop">
            <nuxt-link :to="`/film?type=films&genre=боевик`">Боевики</nuxt-link>
            <nuxt-link :to="`/film?type=films&genre=комедия`">Комедии</nuxt-link>
            <nuxt-link :to="`/film?type=films&genre=детектив`">Детективы</nuxt-link>
            <nuxt-link :to="`/film?type=films&genre=документальный`">Документальные</nuxt-link>
            <nuxt-link :to="`/film?type=films&genre=драма`">Драма</nuxt-link>
            <nuxt-link :to="`/film?type=films&genre=семейный`">Семейные</nuxt-link>
            <nuxt-link :to="`/film?type=films&genre=история`">Исторические</nuxt-link>
            <nuxt-link :to="`/film?type=films&genre=мелодрама`">Мелодрама</nuxt-link>
            <nuxt-link :to="`/film?type=films&genre=спорт`">Спорт</nuxt-link>
            <nuxt-link :to="`/film?type=films&genre=триллер`">Триллер</nuxt-link>
            <nuxt-link :to="`/film?type=films&genre=военный`">Военные</nuxt-link>
            <nuxt-link :to="`/film?type=films&genre=фантастика`">Фантастика</nuxt-link>
            <div v-if="user">
              <nuxt-link :to="`/random?type=films`">Случайный фильм</nuxt-link>
            </div>
          </div>
        </transition>
      </div>

      <div @mouseenter="triggerDrop('c2')" @mouseleave="triggerDrop()" class="item">
        <nuxt-link to="/film?type=serials">
          Сериалы
        </nuxt-link>
        <transition name="fadeIn">
          <div v-show="dropList.c2" class="drop">
            <nuxt-link :to="`/film?type=serials&genre=боевик`">Боевики</nuxt-link>
            <nuxt-link :to="`/film?type=serials&genre=комедия`">Комедии</nuxt-link>
            <nuxt-link :to="`/film?type=serials&genre=детектив`">Детективы</nuxt-link>
            <nuxt-link :to="`/film?type=serials&genre=драма`">Драма</nuxt-link>
            <nuxt-link :to="`/film?type=serials&genre=криминал`">Криминал</nuxt-link>
            <nuxt-link :to="`/film?type=serials&genre=семейный`">Семейные</nuxt-link>
            <nuxt-link :to="`/film?type=serials&genre=история`">Исторические</nuxt-link>
            <nuxt-link :to="`/film?type=serials&genre=мелодрама`">Мелодрама</nuxt-link>
            <nuxt-link :to="`/film?type=serials&genre=спорт`">Спорт</nuxt-link>
            <nuxt-link :to="`/film?type=serials&genre=триллер`">Триллер</nuxt-link>
            <nuxt-link :to="`/film?type=serials&genre=военный`">Военные</nuxt-link>
            <nuxt-link :to="`/film?type=serials&genre=фантастика`">Фантастика</nuxt-link>
            <div v-if="user">
              <nuxt-link :to="`/random?type=serials`">Случайный сериал</nuxt-link>
            </div>
          </div>
        </transition>
      </div>

      <div class="item">
        <nuxt-link :to="'/film?genre=мультфильм'">
          Мультфильмы
        </nuxt-link>
      </div>

      <div @mouseenter="triggerDrop('c3')" @mouseleave="triggerDrop()" class="item">
        <nuxt-link to="/film?type=anime">
          Аниме
        </nuxt-link>
        <transition name="fadeIn">
          <div v-show="dropList.c3" class="drop">
            <nuxt-link :to="`/film?type=anime-serials`">Многосерийные</nuxt-link>
            <nuxt-link :to="`/film?type=anime-serials&genre=спорт`">Спорт</nuxt-link>
            <nuxt-link :to="`/film?type=anime-serials&genre=мелодрама`">Романтика</nuxt-link>
            <div>
              <nuxt-link :to="`/random?type=anime-serials`">Случайное аниме</nuxt-link>
            </div>
          </div>
        </transition>
      </div>

      <div class="item">
        <nuxt-link to="/film?type=tv-show">
          TV-шоу
        </nuxt-link>
      </div>

      <div class="item">
        <nuxt-link :to="`/film?year=${new Date().getFullYear()}&sort=date`">
          Новинки
        </nuxt-link>
      </div>

      <div class="item">
        <nuxt-link :to="`/persons`">
          Персоны
        </nuxt-link>
      </div>
    </div>
    <div v-else class="items">
      <div class="item">
        <nuxt-link :to="'/film?genre=мультфильм'">
          Мультфильмы
        </nuxt-link>
      </div>
    </div>
    <div class="right">
      <div class="buttons">
        <nuxt-link v-if="!user" to="/ways-to-watch" tag="button">
          <img src="/static_files/icons/download.png" alt="" style="display:inline-block;position:relative;top:5px;width:24px;height:24px;">
          Скачать приложение
        </nuxt-link>
        <div v-else />
        <div class="actions">
          <i v-if="!showSearch" @click="showSearch = !showSearch" class="icon-search" />
          <i v-else @click="showSearch = !showSearch" class="icon-close" />
          <i v-if="!user" @click="showDropProfile = !showDropProfile" class="icon-auth" />
          <div v-else @click="showDropProfile = !showDropProfile" :class="{ active: showDropProfile }" class="site-profile">
            <img v-if="isKids" src="/static_files/icons/avatars_kids-0.jpg" alt="Avatar kids">
            <img v-else-if="user.photo" :src="user.photo" alt="User photo">
            <img v-else src="/static_files/icons/avatars_adults-0.jpg" alt="Default avatar">
            <!-- <i class="icon-close" /> -->
          </div>
        </div>
      </div>
      <SearchFilms :payload="{ visible: showSearch, type: 'db', popular: popular, text: '' }" />
      <transition name="fadeIn">
        <DropProfile v-if="showDropProfile" />
      </transition>
    </div>
  </div>
  <div v-else :class="{ stick: stickMenu }" class="mainmenu">
    <nuxt-link to="/" tag="div" class="logo" />
    <div class="right">
      <div class="buttons">
        <div class="actions" :class="{ 'is-auth': user }">
          <i v-if="!showSearch" @click="showSearch = !showSearch" class="icon-search" />
          <i v-else @click="showSearch = !showSearch" class="icon-close" />
          <i v-if="!user" @click="showDropProfile = !showDropProfile" class="icon-burger" />
          <div v-else @click="showDropProfile = !showDropProfile" :class="{ active: showDropProfile }" class="site-profile">
            <i class="icon-burger" />
            <img v-if="isKids" src="/static_files/icons/avatars_kids-0.jpg" alt="Avatar kids">
            <img v-else-if="user.photo" :src="user.photo" alt="User photo">
            <img v-else src="/static_files/icons/avatars_adults-0.jpg" alt="Default avatar">
            <!-- <i class="icon-close" /> -->
          </div>
        </div>
      </div>
      <transition name="fadeIn">
        <div v-if="showDropProfile" class="short-menu">
          <DropProfile />
        </div>
      </transition>

      <SearchFilms :payload="{ visible: showSearch, type: 'db', popular: popular, text: '' }" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  hydrateOnInteraction
} from 'vue-lazy-hydration'
export default {
  components: {
    DropProfile: hydrateOnInteraction(() => import('~/components/DropProfile.vue'), { event: 'click' }),
    SearchFilms: hydrateOnInteraction(() => import('~/components/SearchFilms.vue'), { event: 'click' })
  },
  watch: {
    fullPath() {
      this.showSearch = false
      this.showDropProfile = false
    },
    showSearch(val) {
      if (val) {
        this.showDropProfile = false
      }
    },
    showDropProfile(val) {
      if (val) {
        this.showSearch = false
      }
    }
  },
  computed: {
    ...mapState(['user', 'isKids', 'isDesktop']),
    fullPath() {
      return this.$route.fullPath
    }
  },
  data() {
    return {
      dropList: {
        c1: false,
        c2: false,
        c3: false
      },
      stickMenu: false,
      searchQuery: '',
      showDropProfile: false,
      showSearch: false,
      popular: [
        { title: 'Дюна', link: '/film/409424' },
        { title: 'Игра в кальмара', link: '/film/1301710' },
        { title: 'Веном 2', link: '/film/1227967' },
        { title: 'Гнев человеческий', link: '/film/1318972' },
        { title: 'ИП Пирогова', link: '/film/1195651' }
      ]
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollPage)
    this.stickMenu = !!document.documentElement.scrollTop
  },
  methods: {
    triggerDrop(cat) {
      this.dropList = {
        c1: false,
        c2: false,
        c3: false
      }
      if (cat) {
        this.dropList[cat] = true
      }
    },
    scrollPage(event) {
      this.stickMenu = !!document.documentElement.scrollTop
    }
  }
}
</script>

<style src="~/assets/mainmenu.styl" lang="stylus" />
